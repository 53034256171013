import React, { useState, useEffect } from 'react';
import './App.css';
import Companies from './Companies';
import axios from 'axios';
import MaintenancePage from './MaintenancePage';

const backendURL = process.env.REACT_APP_BACKEND_URL;

function App() {
  const [showModal, setShowModal] = useState(true); // Add this line to control modal visibility
  // Keep the rest of your useState hooks and variables as is

  // Add a function to handle modal close action
  const handleModalClose = (enterSite) => {
    setShowModal(false);
    if (!enterSite) {
      // Redirect away or handle "No" click differently
      window.location.href = 'https://www.google.com';
      console.log("User chose not to enter the site.");
    }
  };

  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    try {
      // @ts-ignore
      const authString = btoa(username + ':' + password);
      // const response = await fetch("https://getnetincome-backend.herokuapp.com/api/verify_credentials", {
      // const backendURL = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${backendURL}/api/verify_credentials`, {
        
        method: 'POST',
        // headers: { 'Content-Type': 'application/json' },
        headers: { 'Content-Type': 'application/json',
        
        'Authorization': 'Basic ' + authString
        },
        // body: JSON.stringify({ username, password })
      });
      
      if (response.status === 200) {
        setLoggedIn(true);
      } else {
        alert("Invalid login credentials");
      }
    } catch (err) {
      console.error("Login failed:", err);
    }
  };
  
  // const showLoginForm = process.env.PASSWORD_PROTECT === 'true';
  const showLoginForm = process.env.REACT_APP_PASSWORD_PROTECT === 'true';

  // console.log(`process.env.PASSWORD_PROTECT -> ${process.env.PASSWORD_PROTECT}`)
  console.log(`process.env.REACT_APP_PASSWORD_PROTECT -> ${process.env.REACT_APP_PASSWORD_PROTECT}`)
  console.log(`showLoginForm -> ${showLoginForm}`);

  var windowWidth = (window.innerWidth || document.documentElement.clientWidth || 0);
  console.log(`windowWidth -> ${windowWidth}`);

  // -----------------------------------------------------------------------------------------------
  const [status, setStatus] = useState('active');

  useEffect(() => {
    const interval = setInterval(() => {
      // axios.get('YOUR_FASTAPI_BACKEND_URL/status')
      axios.get(`${backendURL}/status`)
        .then(response => {
          setStatus(response.data.status);
        })
        .catch(error => {
          console.error('Error fetching app status:', error);
        });
    }, 5000); // Checks every 5 seconds, adjust as needed

    return () => clearInterval(interval);
  }, []);

  if (status === 'maintenance') {
    return <MaintenancePage />;
  }
  // -----------------------------------------------------------------------------------------------

  return (
    <div className="App">
      {showModal && <WelcomeModal onClose={handleModalClose} />}
      {loggedIn || !showLoginForm ? (
        <>
          {/* <h1 className="site-title">upturn stats</h1> */}
          <h1 className="visually-hidden site-title">upturn stats</h1>
          
          {/* <p className="data-credit">data credit: EODHD.</p> */}
          <div className="disclaimer">
            <p>data credit: EODHD.</p>
            <p>do not make any investment decision based on this website.</p>
            <p>data may be inaccurate.</p>
          </div>
          <Companies />
        </>
      ) : (
        <div className="login-container">
          <input type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
          <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
          <button onClick={handleLogin}>Login</button>
          
          
        </div>
      )}
    </div>
  );
}

function WelcomeModal({ onClose }) {
  return (
    <div className="modal-backdrop">
      <div className="modal-container"> 
        <p>Please note that the information provided on this site may be subject to inaccuracies or errors due to factors such as bugs, incorrect data from data sources, or outdated information. This website is not intending to manipulate any company's reputation in any way. Do not make any investment decisions based solely on the information provided on this site. This site does not offer financial advice. This site aims to introduce you to companies you may not yet be familiar with. By clicking 'Yes,' you acknowledge that you understand this disclaimer and agree to proceed with this knowledge.</p>
        <div className="button-container">
          <button className="button button-yes" onClick={() => onClose(true)}>Yes</button>
          <button className="button button-no" onClick={() => onClose(false)}>No</button>
        </div>
      </div>
    </div>
  );
}

export default App;

