import React from 'react';

const MaintenancePage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Site Under Maintenance</h1>
            <p>We are currently performing scheduled maintenance. We should be back shortly. Thank you for your patience.</p>
        </div>
    );
}

export default MaintenancePage;
